// import * as UserApi from '../api/user';
import { saveAs } from 'file-saver';
import { toastr } from 'react-redux-toastr';
import { crud as crudApi, handleCommonApiErrors } from '../api';
import { findNestedObjectValue } from '../containers/helpers';

// action types
export const types = {
  CRUD_LIST_REQUEST: 'CRUD/LIST_REQUEST',
  CRUD_LIST_SUCCESS: 'CRUD/LIST_SUCCESS',
  CRUD_LIST_COUNT_SUCCESS: 'CRUD/LIST_COUNT_SUCCESS',
  CRUD_LIST_ERROR: 'CRUD/LIST_ERROR',

  CRUD_READ_REQUEST: 'CRUD/READ_REQUEST',
  CRUD_READ_SUCCESS: 'CRUD/READ_SUCCESS',
  CRUD_READ_ERROR: 'CRUD/READ_ERROR',
  CRUD_UPDATE_STUDENT_ERROR: 'CRUD/UPDATE_STUDENT_ERROR',

  CRUD_UPDATE_REQUEST: 'CRUD/UPDATE_REQUEST',
  CRUD_UPDATE_SUCCESS: 'CRUD/UPDATE_SUCCESS',
  CRUD_UPDATE_ERROR: 'CRUD/UPDATE_ERROR',

  CRUD_DELETE_REQUEST: 'CRUD/DELETE_REQUEST',
  CRUD_DELETE_SUCCESS: 'CRUD/DELETE_SUCCESS',
  CRUD_DELETE_ERROR: 'CRUD/DELETE_ERROR',

  CRUD_CLONE_REQUEST: 'CRUD/CLONE_REQUEST',
  CRUD_CLONE_SUCCESS: 'CRUD/CLONE_SUCCESS',
  CRUD_CLONE_ERROR: 'CRUD/CLONE_REQUEST',

  CRUD_PUSH_REQUEST: 'CRUD/PUSH_REQUEST',
  CRUD_PUSH_SUCCESS: 'CRUD/PUSH_SUCCESS',
  CRUD_PUSH_ERROR: 'CRUD/PUSH_ERROR',

  CRUD_UPLOAD_REQUEST: 'CRUD/UPLOAD_REQUEST',
  CRUD_UPLOAD_SUCCESS: 'CRUD/UPLOAD_SUCCESS',
  CRUD_UPLOAD_ERROR: 'CRUD/UPLOAD_ERROR',

  CRUD_SIGNUP_REQUEST: 'CRUD/SIGNUP_REQUEST',
  CRUD_SIGNUP_SUCCESS: 'CRUD/SIGNUP_SUCCESS',
  CRUD_SIGNUP_ERROR: 'CRUD/SIGNUP_ERROR',

  CRUD_CLEAR_FILTER: 'CRUD/CLEAR_FILTER',

  CRUD_READ_OTP_SUCCESS: 'CRUD/READ_OTP_SUCCESS',

  CRUD_LIST_UPDATE_SUCCESS: 'CRUD/LIST_UPDATE_SUCCESS',
};

// initial state
const initialState = {
  isLoading: false,
  isLoaded: false,
  list: [],
  listCount: 0,
  record: {},
  isCloned: false,
  resource: '',
};

// reducer
// eslint-disable-next-line complexity
export default (state = initialState, action) => {
  switch (action.type) {
    // list
    case types.CRUD_LIST_REQUEST: {
      const isNewList = action.currentPage === 1;
      if (isNewList)
        return {
          ...initialState,
          isLoading: true,
          isLoaded: false,
        };
      return {
        ...state,
        isLoading: true,
      };
    }
    case types.CRUD_LIST_SUCCESS: {
      const isNewList = action.currentPage === 1;
      return {
        isLoading: false,
        isLoaded: true,
        resource: action.resource,
        data: action.responseData,
        list: isNewList ? [...action.data] : [...state.list, ...action.data],
        listCount: action.count === undefined ? state.listCount : action.count,
        pendingCount: action.pendingCount,
        filter: action.filter,
        filterDetails: action.filterDetails,
        pageName: action.pageName,
        totalAmount: action.totalAmount,
        totalRefund: action.totalRefund,
        totalCardAmount: action.totalCardAmount,
        totalCashAmount: action.totalCashAmount,
        totalOnlineAmount: action.totalOnlineAmount,
        totalAmountReceived: action.totalAmountReceived,
        totalAmountRemaining: action.totalAmountRemaining,
        totalBalance: action.totalBalance,
        totalLinkAmount: action.totalLinkAmount,
        totalTabbyAmount: action.totalTabbyAmount,
      };
    }

    case types.CRUD_LIST_COUNT_SUCCESS: {
      return { ...state, listCount: action.count };
    }

    case types.CRUD_LIST_ERROR:
      return {
        ...state,
        isLoading: false,
        list: [],
        listCount: null,
        isLoaded: false,
      };

    // read
    case types.CRUD_READ_REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoaded: false,
        resource: action.data ? action.data.resource : undefined,
      };

    case types.CRUD_READ_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        record: action.data,
      };

    case types.CRUD_READ_ERROR:
      return { ...state, record: null, isLoading: false };

    case types.CRUD_UPDATE_STUDENT_ERROR:
      return { ...state, isLoading: false };

    // update
    case types.CRUD_UPDATE_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_UPDATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        record: action.data,
      };

    case types.CRUD_UPDATE_ERROR:
      return { ...state, isLoading: false };

    // delete
    case types.CRUD_DELETE_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_DELETE_SUCCESS: {
      const indexOfRecord = state.list.indexOf(action.record);
      // console.log('indexOfRecord: ', indexOfRecord);
      const newListOfRecords = [...state.list];
      newListOfRecords.splice(indexOfRecord, 1);
      return {
        ...state,
        list: [...newListOfRecords],
        isLoading: false,
        record: {},
      };
    }
    case types.CRUD_DELETE_ERROR:
      return { ...state, isLoading: false };

    // clone
    case types.CRUD_CLONE_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_CLONE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isCloned: true,
        record: action.data,
      };
    case types.CRUD_CLONE_ERROR:
      return { ...state, isLoading: false };

    // push
    case types.CRUD_PUSH_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_PUSH_SUCCESS: {
      const indexOfRecord = state.list.indexOf(action.record);
      // console.log('indexOfRecord: ', indexOfRecord);
      const newListOfRecords = [...state.list];
      newListOfRecords.splice(indexOfRecord, 1);
      return {
        ...state,
        list: [...newListOfRecords],
        isLoading: false,
        record: {},
      };
    }
    case types.CRUD_PUSH_ERROR:
      return { ...state, isLoading: false };

    // upload
    case types.CRUD_UPLOAD_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_UPLOAD_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        record: {},
      };
    }
    case types.CRUD_UPLOAD_ERROR:
      return { ...state, isLoading: false };

    // sign up
    case types.CRUD_SIGNUP_REQUEST:
      return { ...state, isLoading: true };

    case types.CRUD_SIGNUP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        // record: action.data,
        verify: action.data,
      };

    case types.CRUD_SIGNUP_ERROR:
      return { ...state, isLoading: false };
    case types.CRUD_CLEAR_FILTER:
      return {
        ...state,
        filter: {},
        pageName: '',
      };
    case types.CRUD_READ_OTP_SUCCESS:
      return {
        ...state,
        isLoading: false,
      };
    case types.CRUD_LIST_UPDATE_SUCCESS: {
      const indexOfRecord = state.list.indexOf(action.record);
      const newListOfRecords = [...state.list];
      newListOfRecords[indexOfRecord] = action.record;
      return {
        ...state,
        list: [...newListOfRecords],
        isLoading: false,
        record: {},
      };
    }
    default:
      return state;
  }
};

// action creators & async actions
export const actions = {
  getList: (request, filterDetails) => async (dispatch) => {
    // console.log('request: ', request);
    dispatch({
      type: types.CRUD_LIST_REQUEST,
      currentPage: request.pagination.page,
    });
    try {
      const toBeExcluded = /^integrator|^schedule|^user\/v1\/ticket/.test(
        request.resource
      );

      if (toBeExcluded || request.withCount === true) {
        const response = await crudApi.getList(request);
        let { data } = response;
        const responseData = data;
        if (data && request.source) {
          data = findNestedObjectValue(data, request.source.split('.'));
        }
        dispatch({
          type: types.CRUD_LIST_SUCCESS,
          data,
          filterDetails,
          responseData,
          count: Number(response.count),
          pendingCount: response.pendingCount
            ? Number(response.pendingCount)
            : 0,
          currentPage: request.pagination.page,
          filter: request.filter,
          pageName: request.name,
          totalAmount: response.totalAmount
            ? Number(response.totalAmount).toFixed(2)
            : 0,
          totalCardAmount: response.totalCardAmount
            ? Number(response.totalCardAmount).toFixed(2)
            : 0,
          totalCashAmount: response.totalCashAmount
            ? Number(response.totalCashAmount).toFixed(2)
            : 0,
          totalOnlineAmount: response.totalOnlineAmount
            ? Number(response.totalOnlineAmount).toFixed(2)
            : 0,
          totalRefund: response.totalRefund
            ? Number(response.totalRefund).toFixed(2)
            : 0,
          totalAmountReceived: response.totalAmountReceived
            ? Number(response.totalAmountReceived).toFixed(2)
            : 0,
          totalAmountRemaining: response.totalAmountRemaining
            ? Number(response.totalAmountRemaining).toFixed(2)
            : 0,
          totalBalance: response.totalBalance
            ? Number(response.totalBalance).toFixed(2)
            : 0,
          totalLinkAmount: response.totalLinkAmount
            ? Number(response.totalLinkAmount).toFixed(2)
            : 0,
          totalTabbyAmount: response.totalTabbyAmount
            ? Number(response.totalTabbyAmount).toFixed(2)
            : 0,
        });
        return;
      }

      await Promise.all[
        ((async function getOnlyListItems() {
          const response = await crudApi.getList(request, {
            getcount: false,
          });
          let { data } = response;
          const responseData = data;
          if (data && request.source) {
            data = findNestedObjectValue(data, request.source.split('.'));
          }
          dispatch({
            type: types.CRUD_LIST_SUCCESS,
            data,
            responseData,
            // count: Number(response.count),
            pendingCount: response.pendingCount
              ? Number(response.pendingCount)
              : 0,
            currentPage: request.pagination.page,
            filter: request.filter,
            pageName: request.name,
            totalAmount: response.totalAmount
              ? Number(response.totalAmount).toFixed(2)
              : 0,
            totalCardAmount: response.totalCardAmount
              ? Number(response.totalCardAmount).toFixed(2)
              : 0,
            totalCashAmount: response.totalCashAmount
              ? Number(response.totalCashAmount).toFixed(2)
              : 0,
            totalOnlineAmount: response.totalOnlineAmount
              ? Number(response.totalOnlineAmount).toFixed(2)
              : 0,
            totalRefund: response.totalRefund
              ? Number(response.totalRefund).toFixed(2)
              : 0,
            totalAmountReceived: response.totalAmountReceived
              ? Number(response.totalAmountReceived).toFixed(2)
              : 0,
            totalAmountRemaining: response.totalAmountRemaining
              ? Number(response.totalAmountRemaining).toFixed(2)
              : 0,
            totalBalance: response.totalBalance
              ? Number(response.totalBalance).toFixed(2)
              : 0,
            totalLinkAmount: response.totalLinkAmount
              ? Number(response.totalLinkAmount).toFixed(2)
              : 0,
            totalTabbyAmount: response.totalTabbyAmount
              ? Number(response.totalTabbyAmount).toFixed(2)
              : 0,
          });
        })(),
        (async function getOnlyListCount() {
          if (request.pagination && request.pagination.page === 1) {
            const response = await crudApi.getList(request, {
              getcount: true,
            });
            dispatch({
              type: types.CRUD_LIST_COUNT_SUCCESS,
              count: Number(response.count),
            });
          }
        })())
      ];
    } catch (error) {
      dispatch({ type: types.CRUD_LIST_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  get: (request) => async (dispatch) => {
    dispatch({
      type: types.CRUD_READ_REQUEST,
      data: { resource: request.resource },
    });
    try {
      const response = await crudApi.get(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_READ_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.CRUD_READ_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  create: (request, data) => async (dispatch) => {
    dispatch({
      type: types.CRUD_READ_REQUEST,
      data: { resource: request.resource },
    });
    try {
      const response = await crudApi.create(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_READ_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Creation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_READ_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  update: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_UPDATE_REQUEST });
    try {
      const response = await crudApi.update(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_UPDATE_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Updation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  updateByToken: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_UPDATE_REQUEST });
    try {
      const response = await crudApi.updateByToken(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_UPDATE_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Updation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  clone: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_CLONE_REQUEST });
    try {
      const response = await crudApi.clone(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_CLONE_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Clone Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_CLONE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  remove: (request, record) => async (dispatch) => {
    dispatch({ type: types.CRUD_DELETE_REQUEST });
    try {
      await crudApi.remove(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_DELETE_SUCCESS,
        record,
      });
      toastr.success('Success', 'Delete successful!');
    } catch (error) {
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  removeByWithoutId: (request, record) => async (dispatch) => {
    dispatch({ type: types.CRUD_DELETE_REQUEST });
    try {
      await crudApi.removeByWithoutId(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_DELETE_SUCCESS,
        record,
      });
      toastr.success('Success', 'Delete successful!');
    } catch (error) {
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  push: (request, record) => async (dispatch) => {
    dispatch({ type: types.CRUD_PUSH_REQUEST });
    try {
      await crudApi.push(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_PUSH_SUCCESS,
        record,
      });
      toastr.success('Success', 'Pushed Successfull!');
    } catch (error) {
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },
  saveCoupon: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_UPLOAD_REQUEST });
    try {
      if (data && !data.data) {
        toastr.error('Error', 'Please select file!');
        dispatch({
          type: types.CRUD_UPLOAD_ERROR,
        });
      } else if (data && data.data && !data.data.coupons) {
        toastr.error('Error', 'Please select file!');
        dispatch({
          type: types.CRUD_UPLOAD_ERROR,
        });
      } else {
        await crudApi.saveCoupon(request, data);
        // console.log('response(success): ', response);
        dispatch({
          type: types.CRUD_UPLOAD_SUCCESS,
          data,
        });
        toastr.success('Success', 'Upload Successfull!');
      }
    } catch (error) {
      dispatch({
        type: types.CRUD_UPLOAD_ERROR,
      });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  signUp: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_SIGNUP_REQUEST });
    try {
      const response = await crudApi.create(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_SIGNUP_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Creation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_SIGNUP_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },
  downloadInvoice: (request, paymentId, onComplete) => async () => {
    try {
      const response = await crudApi.downloadInvoicePdf(request, paymentId);
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `invoice-${paymentId}.pdf`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download pdf'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },

  downloadPreRegistrationInvoice: (
    request,
    paymentId,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadPreRegistrationInvoicePdf(
        request,
        paymentId
      );
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `invoice-${paymentId}.pdf`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download pdf'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },

  downloadCorporateInvoice: (
    request,
    paymentId,
    corporateId,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadCorporateInvoice(
        request,
        paymentId,
        corporateId
      );
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `invoice-${paymentId}.pdf`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download pdf'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },

  downloadLumpsumCorporateInvoice: (
    request,
    paymentId,
    corporateId,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadLumpsumCorporateInvoice(
        request,
        paymentId,
        corporateId
      );
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `invoice-${paymentId}.pdf`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download pdf'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },

  downloadLumpSumInvoice: (
    request,
    paymentId,
    userId,
    invoiceNumber,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadLumpSumInvoice(
        request,
        paymentId,
        userId,
        invoiceNumber
      );
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `invoice-${paymentId}.pdf`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download pdf'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadContract: (request, userId, onComplete) => async () => {
    try {
      const response = await crudApi.downloadContractPdf(request, userId);
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `contract-${userId}.pdf`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download pdf'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },

  downloadCoupons: (request, type, id, onComplete) => async () => {
    try {
      const response = await crudApi.downloadCoupon(request);
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      saveAs(data, `coupon-${type}-${id}.xlsx`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download csv'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadTrainingSheet: (request, userId, code, onComplete) => async () => {
    try {
      const response = await crudApi.downloadTrainingSheet(
        request,
        userId,
        code
      );
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `training-sheet-${userId}.pdf`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download pdf'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadSanityLog: (request, onComplete, date, trafficFileNo) => async () => {
    try {
      const response = await crudApi.downloadCTSSanityLog(
        request,
        date,
        trafficFileNo
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName = `sanityLog${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadCTSStudentLog: (
    request,
    startDate,
    endDate,
    trafficFileNo,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadCTSStudentLog(
        request,
        startDate,
        endDate,
        trafficFileNo
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName = `CtsStudentData${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadCTSLicenseLog: (
    request,
    startDate,
    endDate,
    trafficFileNo,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadCTSLicenseLog(
        request,
        startDate,
        endDate,
        trafficFileNo
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName = `CtsLicenseData${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  clearFilter: () => async (dispatch) => {
    dispatch({ type: types.CRUD_CLEAR_FILTER });
  },
  updateStudent: (request, data) => async (dispatch) => {
    dispatch({
      type: types.CRUD_READ_REQUEST,
      data: { resource: request.resource },
    });
    try {
      const response = await crudApi.update(request, data);
      if (response && response.data.isShowOtp) {
        dispatch({
          type: types.CRUD_READ_OTP_SUCCESS,
          data: response.data,
        });
      } else {
        dispatch({
          type: types.CRUD_READ_SUCCESS,
          data: response.data,
        });
        toastr.success('Success', 'Updation Successfull!');
      }
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_STUDENT_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
      throw error;
    }
  },
  updateCompany: (request, data) => async (dispatch) => {
    // dispatch({
    //   type: types.CRUD_READ_REQUEST,
    //   data: { resource: request.resource },
    // });
    try {
      const response = await crudApi.update(request, data);
      if (response && response.data._id) {
        dispatch({
          type: types.CRUD_UPDATE_SUCCESS,
          data: response.data,
        });
        toastr.success('Success', 'Updation Successfull!');
      }
      toastr.success('Success', 'Updation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_STUDENT_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },
  getAsbsentClass: (request) => async (dispatch) => {
    dispatch({
      type: types.CRUD_LIST_REQUEST,
      currentPage: request.pagination.page,
    });
    try {
      const response = await crudApi.getAsbsentClass(request);
      dispatch({
        type: types.CRUD_LIST_SUCCESS,
        data: response.data,
        count: Number(response.count),
        pendingCount: response.pendingCount ? Number(response.pendingCount) : 0,
        currentPage: request.pagination.page,
        filter: request.filter,
        pageName: request.name,
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.CRUD_LIST_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },
  downloadRefundDocument: (userId, isCorporate, onComplete) => async () => {
    try {
      const response = await crudApi.downloadRefundDocument(
        userId,
        isCorporate
      );
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `refund-document-${userId}.pdf`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download pdf'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },

  downloadStudentList: (
    startDate,
    endDate,
    licenseTypeId,
    branchId,
    corporateId,
    studentStatus,
    onComplete,
    stageCode,
    studentType,
    courseTypeId,
    reportType
  ) => async () => {
    try {
      const response = await crudApi.downloadStudentList(
        startDate,
        endDate,
        licenseTypeId,
        branchId,
        corporateId,
        studentStatus,
        stageCode,
        studentType,
        courseTypeId,
        reportType
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName = `StudentData${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadTestList: (
    startDate,
    endDate,
    testType,
    licenseTypeId,
    onComplete,
    branchId,
    testBranchCode,
    courseTypeId,
    studentType
  ) => async () => {
    try {
      const response = await crudApi.downloadTestList(
        startDate,
        endDate,
        testType,
        licenseTypeId,
        branchId,
        testBranchCode,
        courseTypeId,
         studentType
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName = `TestData${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadClearanceCertificateDocument: (userId, onComplete) => async () => {
    try {
      const response = await crudApi.downloadClearanceCertificateDocument(
        userId
      );
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `clearance-certificate-${userId}.pdf`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download pdf'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadAssessmentList: (
    startDate,
    endDate,
    testType,
    licenseTypeId,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadAssessmentList(
        startDate,
        endDate,
        testType,
        licenseTypeId
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName = `AssessmentData${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadExaminerPerformance: (
    startDate,
    endDate,
    testType,
    licenseTypeId,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadExaminerPerformance(
        startDate,
        endDate,
        testType,
        licenseTypeId
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName = `ExaminerPerformance${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadInstructorReport: (
    startDate,
    endDate,
    gender,
    licenseTypeId,
    branchId,
    permitType,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadInstructorReport(
        startDate,
        endDate,
        gender,
        licenseTypeId,
        branchId,
        permitType
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName = `InstructorData${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },

  updateRemaining: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_UPDATE_REQUEST });
    try {
      const response = await crudApi.updateRemaining(request, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_UPDATE_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Updation Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  getCorporateInvoice: (request) => async (dispatch) => {
    dispatch({
      type: types.CRUD_READ_REQUEST,
      data: { resource: request.resource },
    });
    try {
      const response = await crudApi.getCorporateInvoice(request);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_READ_SUCCESS,
        data: response.data,
      });
    } catch (error) {
      // console.log(error);
      dispatch({ type: types.CRUD_READ_ERROR });
      if (error.response && error.response.data) {
        toastr.error('Error', error.response.data.message);
      }
      handleCommonApiErrors(error);
    }
  },

  downloadLectureAttendance: (
    startDate,
    endDate,
    licenseTypeId,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadLectureAttendance(
        startDate,
        endDate,
        licenseTypeId
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName = `LectureAttendance${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadStudentListWithoutLearningPermitNumber: (
    startDate,
    endDate,
    onComplete,
    isRtaFileIdExists
  ) => async () => {
    try {
      const response = await crudApi.downloadStudentListWithoutLearningPermitNumber(
        startDate,
        endDate,
        isRtaFileIdExists
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const fileName = `StudentWithoutPermitNumberData${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  downloadOnlineLectureAttendance: (
    date,
    learningPermitNumber,
    licenseTypeId,
    onComplete
  ) => async () => {
    try {
      const response = await crudApi.downloadOnlineLectureAttendance(
        date,
        learningPermitNumber,
        licenseTypeId
      );
      const data = new Blob([response.data], {
        type:
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      const fileName = `OnlineLectureAttendance${Date.now()}.xlsx`;
      saveAs(data, fileName);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download xlsx'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
  changeGearType: (id, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_UPDATE_REQUEST });
    try {
      const response = await crudApi.changeGearType(id, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_UPDATE_SUCCESS,
        data: response.data,
      });
      toastr.success('Success', 'Gear Type Changed');
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  changeGender: (id, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_UPDATE_REQUEST });
    try {
      const response = await crudApi.changeGender(id, data);
      // console.log('response(success): ', response);
      dispatch({
        type: types.CRUD_UPDATE_SUCCESS,
        data: response.data,
      });

      toastr.success('Success', 'Gender Changed');
    } catch (error) {
      dispatch({ type: types.CRUD_UPDATE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  cancelPreregistrationInvoice: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_CLONE_REQUEST });
    try {
      const response = await crudApi.cancelPreregistrationInvoice(
        request,
        data,
        'cancel'
      );
      const updatedRecord = data;
      updatedRecord.recordStatus = 'archive';
      updatedRecord.preRegistrationAmount = 0;
      updatedRecord.advancePaymentInvoiceId = response.data?._id;
      dispatch({
        type: types.CRUD_LIST_UPDATE_SUCCESS,
        record: updatedRecord,
      });
      toastr.success('Success', 'Cancel Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_CLONE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  refundPreregistrationInvoice: (request, data) => async (dispatch) => {
    dispatch({ type: types.CRUD_CLONE_REQUEST });
    try {
      const response = await crudApi.cancelPreregistrationInvoice(
        request,
        data,
        'refund'
      );
      const updatedRecord = data;
      updatedRecord.recordStatus = 'refunded';
      updatedRecord.preRegistrationAmount = 0;
      updatedRecord.advancePaymentInvoiceId = response.data?._id;
      dispatch({
        type: types.CRUD_LIST_UPDATE_SUCCESS,
        record: updatedRecord,
      });
      toastr.success('Success', 'Refund Successfull!');
    } catch (error) {
      dispatch({ type: types.CRUD_CLONE_ERROR });
      serializeAndShowFormErrors(error);
      handleCommonApiErrors(error);
    }
  },

  downloadCollectionReport: (request, filters, onComplete) => async () => {
    try {
      const response = await crudApi.downloadCollectionReportPdf(
        request,
        filters
      );
      const data = new Blob([response.data], { type: 'application/pdf' });
      saveAs(data, `SalesmanCollectionReport.pdf`);
    } catch (error) {
      toastr.error(
        'Error',
        (error &&
          error.response &&
          error.response.data &&
          error.response.data.message) ||
          'Failed to download pdf'
      );
      handleCommonApiErrors(error);
    } finally {
      if (onComplete) {
        onComplete();
      }
    }
  },
};

export function serializeAndShowFormErrors(error) {
  if (error.response && error.response.data && error.response.data.errors) {
    const { errors } = error.response.data;
    Object.keys(errors).forEach((e) => {
      // console.log('error: ', e, errors[e].message);
      toastr.error(errors[e].message);
    });
    return;
  }
  if (error.response && error.response.data && error.response.data.message) {
    toastr.error(error.response.data.message);

    return;
  }
  if (error.response && error.response.data && error.response.data.error) {
    toastr.error(error.response.data.message);

    return;
  }
  toastr.error('Error', 'Invalid Data in Form!');
}
